import axios from "axios";

export default axios.create({
  //  baseURL: "http://localhost:8000/",
  // baseURL: "http://207.148.69.16:8003/",
   baseURL: "http://139.180.138.120:8000/",
  // baseURL: "https://naa-erp-api.ftpos.pk/",
  headers: {
    "Content-type": "application/json",
    "Authorization": "JWT " + localStorage.getItem("jwt")
  }
});

var fileAPI = axios.create({
  // baseURL: "http://localhost:8000/",
  // baseURL: "http://207.148.69.16:8003/",
  baseURL: "http://139.180.138.120:8000/",
  // baseURL: "https://naa-erp-api.ftpos.pk/",
  headers: {
    "Content-type": "multipart/form-data",
    "Authorization": "JWT " + localStorage.getItem("jwt")
  }
});
