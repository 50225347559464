import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import BootstrapVue from 'bootstrap-vue'
import { BootstrapVueIcons } from 'bootstrap-vue'
import './assets/style.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker';
// @ts-ignore
import VueExcelXlsx from "vue-excel-xlsx";
// @ts-ignore
import HighchartsVue from 'highcharts-vue'
const moment = require('moment')


Vue.use(BootstrapVue);
Vue.use(VueExcelXlsx)
Vue.use(BootstrapVueIcons)
Vue.use(require('vue-moment'), {
  moment
})
Vue.component('v-select', vSelect)
Vue.component('date-picker', DatePicker)
Vue.use(HighchartsVue)





Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

