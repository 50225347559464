import Vue from 'vue'
import VueRouter, { RouteConfig} from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', name: 'Home', component: Home, meta: { title: 'Home' } },

  { path: '/employee', name: 'Employee', meta: { title: 'Employee', requiresAuth: true }, component: () => import('../views/hrm/employee/employee.vue') },
  { path: '/create-employee', name: 'Create', props: true, meta: { title: 'Create Employee', requiresAuth: true }, component: () => import('../views/hrm/employee/create.vue') },

  { path: '/salary', name: 'Salary', meta: { title: 'Salary', requiresAuth: true }, component: () => import('../views/hrm/salary/salary.vue') },
  { path: '/create-salary', name: 'Create-Salary', props: true, meta: { title: 'Create Salary', requiresAuth: true }, component: () => import('../views/hrm/salary/create.vue') },

  { path: '/attendance', name: 'Attendance', meta: { title: 'Attendance', requiresAuth: true }, component: () => import('../views/hrm/attendance/attendance.vue') },
  { path: '/create-attendance', name: 'Create-Attendance', props: true, meta: { title: 'Create Attendance', requiresAuth: true }, component: () => import('../views/hrm/attendance/create.vue') },

  { path: '/payroll', name: 'Payroll', meta: { title: 'Payroll', requiresAuth: true }, component: () => import('../views/hrm/payroll/payroll.vue') },  
  { path: '/payroll-create', name: 'Payroll-Create', props: true, meta: { title: 'Payroll', requiresAuth: true }, component: () => import('../views/hrm/payroll/create.vue') },  
  { path: '/payroll-slip/:id', name: 'Payroll-Slip', meta: { title: 'Payroll', requiresAuth: true }, component: () => import('../views/hrm/payroll/payslip.vue') },

  { path: '/hrm/work-location', name: 'work-location', meta: { title: 'work-location', requiresAuth: true }, component: () => import('../views/hrm/work-location/work-location.vue') },
  //here
  { path: '/hrm/iqama', name: 'iqama', meta: { title: 'iqama', requiresAuth: true }, component: () => import('../views/hrm/iqama/iqama.vue') },
  { path: '/hrm/work-permit', name: 'work-permit', meta: { title: 'work-permit', requiresAuth: true }, component: () => import('../views/hrm/work-permit/work-permit.vue') },
  { path: '/hrm/medical', name: 'medical', meta: { title: 'medical', requiresAuth: true }, component: () => import('../views/hrm/medical/medical.vue') },
  { path: '/hrm/document', name: 'document', meta: { title: 'document', requiresAuth: true }, component: () => import('../views/hrm/documents/documents.vue') },
  { path: '/hrm/education', name: 'education', meta: { title: 'education', requiresAuth: true }, component: () => import('../views/hrm/education/education.vue') },
  { path: '/hrm/passport', name: 'passport', meta: { title: 'passport', requiresAuth: true }, component: () => import('../views/hrm/passport/passport.vue') },
  { path: '/hrm/visa', name: 'visa', meta: { title: 'visa', requiresAuth: true }, component: () => import('../views/hrm/visa/visa.vue') },
  { path: '/hrm/driving-license', name: 'driving-license', meta: { title: 'driving-license', requiresAuth: true }, component: () => import('../views/hrm/driving-license/driving-license.vue') },
  { path: '/hrm/project', name: 'project', meta: { title: 'project', requiresAuth: true }, component: () => import('../views/hrm/project/project.vue') },





  { path: '/list', name: 'List', meta: { title: 'List', requiresAuth: true }, component: () => import('../views/project/list/list.vue') },
  { path: '/project-create-list', name: 'project-create-list', props: true, meta: { title: 'project-create-list', requiresAuth: true }, component: () => import('../views/project/list/create-list.vue') },

  { path: '/order', name: 'order', meta: { title: 'Order', requiresAuth: true }, component: () => import('../views/project/order/order.vue') },
  { path: '/create-order', name: 'Create-Order', meta: { title: 'Create Order', requiresAuth: true }, component: () => import('../views/project/order/create-order.vue') },

  { path: '/add-order-force', name: 'Add-Order-Force', meta: { title: 'Add-Order-Force', requiresAuth: true }, component: () => import('../views/project/add-order-force/add-order-force.vue') },
  { path: '/create-order-force', name: 'Create-Order-Force', meta: { title: 'Create-Order-Force', requiresAuth: true }, component: () => import('../views/project/add-order-force/create-order-force.vue') },

  { path: '/assets-list', name: 'Assets-List', meta: { title: 'Assets-List', requiresAuth: true }, component: () => import('../views/assets/assets-list/assets-list.vue') },
  { path: '/create-assets', name: 'Create-Assets', meta: { title: 'Create-Assets', requiresAuth: true }, component: () => import('../views/assets/assets-list/create-assets.vue') },

  { path: '/administration', name: 'Administration', meta: { title: 'Administration', requiresAuth: true }, component: () => import('../views/settings/administration/administration.vue') },
  { path: '/assets', name: 'assets', meta: { title: 'Assets', requiresAuth: true }, component: () => import('../views/settings/administration/assets/assets.vue') },
  { path: '/blood-group', name: 'blood-group', meta: { title: 'Blood-Group', requiresAuth: true }, component: () => import('../views/settings/administration/blood-group/blood-group.vue') },
  { path: '/branch', name: 'branch', meta: { title: 'Branch', requiresAuth: true }, component: () => import('../views/settings/administration/branch/branch.vue') },
  { path: '/department', name: 'department', meta: { title: 'Department', requiresAuth: true }, component: () => import('../views/settings/administration/department/department.vue') },
  { path: '/designations', name: 'designations', meta: { title: 'Designations', requiresAuth: true }, component: () => import('../views/settings/administration/designations/designations.vue') },
  { path: '/education-details', name: 'education-details', meta: { title: 'Education-Details', requiresAuth: true }, component: () => import('../views/settings/administration/education-details/education-details.vue') },
  { path: '/employment-types', name: 'employment-types', meta: { title: 'Employment-Types', requiresAuth: true }, component: () => import('../views/settings/administration/employment-types/employment-types.vue') },
  { path: '/expats', name: 'expats', meta: { title: 'Expats', requiresAuth: true }, component: () => import('../views/settings/administration/expats/expats.vue') },
  { path: '/nationality', name: 'nationality', meta: { title: 'Nationality', requiresAuth: true }, component: () => import('../views/settings/administration/nationality/nationality.vue') },
  { path: '/sub-branch', name: 'sub-branch', meta: { title: 'Sub-Branch', requiresAuth: true }, component: () => import('../views/settings/administration/sub-branch/sub-branch.vue') },
  { path: '/chartsofaccounts', name: 'ChartsOfAccounts', meta: { title: 'Charts', requiresAuth: true }, component: () => import('../views/accounts/chartsofaccounts.vue') },
  { path: '/import-admin', name: 'Import-admin', meta: { title: 'Import', requiresAuth: true }, component: () => import('../views/settings/administration/import/import.vue') },

  // Vendor
  { path: '/vendor/list', name: 'vendor', meta: { title: 'Vendor', requiresAuth: true }, component: () => import('../views/vendor/list.vue') },

  // SALES
  { path: '/sales', name: 'Sales', meta: { title: 'Sales', requiresAuth: true }, component: () => import('../views/accounts/sales/sales.vue') },
  { path: '/invoice/sales-invoice-riyadh/:id', name: 'Sale-Invoice-aramco', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-aramco-riyadh.vue') },
  { path: '/invoice/sales-invoice-yumbu/:id', name: 'Sale-Invoice-aramco', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-aramco-yumbu.vue') },
  { path: '/invoice/sales-invoice-juaymah/:id', name: 'Sale-Invoice-aramco', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-aramco-juaymah.vue') },
  { path: '/invoice/sales-invoice-tanajeeb/:id', name: 'Sale-Invoice-tanajeeb', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-tanajeeb.vue') },
  { path: '/invoice/sales-invoice-yasref/:id', name: 'Sale-Invoice-yasref', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-yasref.vue') },
  { path: '/invoice/sales-invoice-yanbu/:id', name: 'Sale-Invoice-yanbu', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-yanbu.vue') },
  { path: '/invoice/sales-invoice-grainsailos/:id', name: 'Sale-Invoice-GrainSailos', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-grain-sailos.vue') },
  { path: '/invoice/sales-invoice-marafiq/:id', name: 'Sale-Invoice-marafiq', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-marafiq.vue') },
  { path: '/invoice/sales-invoice-jeddah/:id', name: 'Sale-Invoice-jeddah', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-jeddah.vue') },
  { path: '/invoice/sales-invoice-kjo/:id', name: 'Sale-Invoice-kjo', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-kjo.vue') },
  { path: '/invoice/sales-invoice-grain-sailos/:id', name: 'Sale-Invoice-grain-sailos', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-grain-sailos.vue') },
  { path: '/invoice/sales-invoice-bahria/:id', name: 'Sale-Invoice-bahria', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-bahria.vue') },
  { path: '/invoice/sales-invoice-bahria-new/:id', name: 'Sale-Invoice-bahria', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-bahria-new.vue') },
  { path: '/invoice/sales-invoice-milling/:id', name: 'Sale-Invoice-milling', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-milling.vue') },
  { path: '/invoice/sales-invoice-aramco-arabic/:id', name: 'Sale-Invoice-aramco-arabic', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-aramco-arabic.vue') },
  { path: '/invoice/sales-invoice-saptco/:id', name: 'Sale-Invoice-saptco', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-saptco.vue') },
  { path: '/invoice/sales-invoice-philip/:id', name: 'Sale-Invoice-philip', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-philip.vue') },
  { path: '/invoice/sales-invoice-air-fueling-makkah/:id', name: 'Sale-Invoice-air-fueling', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-air-fueling-makkah.vue') },
  { path: '/invoice/sales-invoice-air-fueling-aseer/:id', name: 'Sale-Invoice-air-fueling', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-air-fueling-aseer.vue') },
  { path: '/invoice/sales-invoice-air-fueling-riyadh/:id', name: 'Sale-Invoice-air-fueling', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-air-fueling-riyadh.vue') },
  { path: '/invoice/sales-invoice-air-fueling-shirqiya/:id', name: 'Sale-Invoice-air-fueling', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-air-fueling-shirqiya.vue') },
  { path: '/invoice/sales-invoice-warning/:id', name: 'Sale-Invoice-warning', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-warning.vue') },
  { path: '/invoice/sales-invoice-tameed/:id', name: 'Sale-Invoice-Tameed', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-tameed.vue') },
  { path: '/invoice/sales-invoice-tameed-mod/:id', name: 'Sale-Invoice-Tameed', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-tameed-mod.vue') },
  { path: '/invoice/sales-invoice-aramco-gmc/:id', name: 'Sale-Invoice-GMC', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-aramco-gmc.vue') },
  { path: '/invoice/sales-invoice-aramco-mastoura/:id', name: 'Sale-Invoice-GMC', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-aramco-mastoura.vue') },
  { path: '/invoice/sales-invoice-authentix/:id', name: 'Sale-Invoice-GMC', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-authentix.vue') },
  { path: '/invoice/sales-invoice-alarji/:id', name: 'Sale-Invoice-GMC', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-alarji.vue') },
  { path: '/invoice/sales-invoice-HVAC-Maintenance-crdd/:id', name: 'HVAC-Maintenance-crdd', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-hvac-maintenance.vue') },
  { path: '/invoice/generate-invoice', name: 'Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/generate-invoice.vue') },

  { path: '/invoice/generate-invoice-credit', name: 'Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/generate-invoice-credit.vue') },
  { path: '/invoice/sales-invoice-yumbu-credit/:id', name: 'Sale-Invoice-aramco', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-aramco-yumbu-credit.vue') },
  { path: '/invoice/sales-invoice-riyadh-credit/:id', name: 'Sale-Invoice-aramco', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-aramco-riyadh-credit.vue') },
  { path: '/invoice/sales-invoice-jeddah-credit/:id', name: 'Sale-Invoice-jeddah', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-jeddah-credit.vue') },
  { path: '/invoice/sales-invoice-tanajeeb-credit/:id', name: 'Sale-Invoice-tanajeeb', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-tanajeeb-credit.vue') },
  { path: '/invoice/sales-invoice-milling-credit/:id', name: 'Sale-Invoice-milling', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-milling-credit.vue') },
  { path: '/invoice/sales-invoice-aramco-mastoura-credit/:id', name: 'Sale-Invoice-mastoura-credit', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-invoice-aramco-mastoura-credit.vue') },

  { path: '/invoice/generate-credit-general', name: 'Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/generate-credit-general.vue') },
  { path: '/invoice/sales-credit-jeddah-general/:id', name: 'Sale-Invoice-aramco', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/accounts/sales/invoice/sales-credit-jeddah-general.vue') },


  // Finance
  { path: '/finance', name: 'Finance', meta: { title: 'Finance', requiresAuth: true }, component: () => import('../views/accounts/finance/finance.vue') },

  // product
  { path: '/product/item', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/list/list.vue') },
  { path: '/product/stock/list', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/stock/list.vue') },
  { path: '/product/stock/stock-list', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/stock/stock-list.vue') },
  { path: '/product/stock/list-in-print/:id', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/stock/list-in-print.vue') },  
  { path: '/product/stock/list-out', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/stock/list-out.vue') },
  { path: '/product/stock/list-out-print/:id', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/stock/list-out-print.vue') },  
  { path: '/product/master/index', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/master/index.vue') },
  { path: '/product/master/category', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/master/category.vue') },
  { path: '/product/master/category-level-1', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/master/category-level-1.vue') },
  { path: '/product/master/category-level-2', name: 'product', meta: { title: 'Product', requiresAuth: true }, component: () => import('../views/product/master/category-level-2.vue') },
  
  
  // Journal
  { path: '/journal/entry', name: 'Journals', meta: { title: 'Journals', requiresAuth: true }, component: () => import('../views/accounts/finance/journal/entry.vue') },
  { path: '/journal/complex-entry', name: 'Journals', meta: { title: 'Journals', requiresAuth: true }, component: () => import('../views/accounts/finance/journal/complex-entry.vue') },
  { path: '/journal/book-income', name: 'Journals', meta: { title: 'Journals', requiresAuth: true }, component: () => import('../views/accounts/finance/journal/book-income.vue') },
  { path: '/journal/book-expense', name: 'Journals', meta: { title: 'Journals', requiresAuth: true }, component: () => import('../views/accounts/finance/journal/book-expense.vue') },
  // receipts
  { path: '/receipt/receipt-from-parties', name: 'Receipts', meta: { title: 'Receipts', requiresAuth: true }, component: () => import('../views/accounts/finance/receipts/receipt-from-parties.vue') },
  { path: '/receipt/cashreceipts', name: 'Receipts', meta: { title: 'Receipts', requiresAuth: true }, component: () => import('../views/accounts/finance/receipts/cash-receipts.vue') },
  { path: '/receipt/bankreceipts', name: 'Receipts', meta: { title: 'Receipts', requiresAuth: true }, component: () => import('../views/accounts/finance/receipts/bank-receipts.vue') },

  // Payments
  { path: '/payments/bank-payments', name: 'Payments', meta: { title: 'Payments', requiresAuth: true }, component: () => import('../views/accounts/finance/payments/bank-payments.vue') },
  { path: '/payments/cash-payments', name: 'Payments', meta: { title: 'Payments', requiresAuth: true }, component: () => import('../views/accounts/finance/payments/cash-payments.vue') },
  { path: '/payments/cash-transfer', name: 'Payments', meta: { title: 'Payments', requiresAuth: true }, component: () => import('../views/accounts/finance/payments/cash-transfer.vue') },
  // Banking
  { path: '/banking/cash-desposit-withdrawals', meta: { title: 'Banking', requiresAuth: true }, name: 'Banking', component: () => import('../views/accounts/finance/banking/cash-deposit-withdrawals.vue') },
  { path: '/banking/inter-bank-funds-transfer', meta: { title: 'Banking', requiresAuth: true }, name: 'Banking', component: () => import('../views/accounts/finance/banking/inter-bank-funds-transfer.vue') },
  { path: '/banking/bank-reconciliation', meta: { title: 'Banking', requiresAuth: true }, name: 'Banking', component: () => import('../views/accounts/finance/banking/bank-reconciliation.vue') },
  { path: '/banking/issued-cheques-bounce', meta: { title: 'Banking', requiresAuth: true }, name: 'Banking', component: () => import('../views/accounts/finance/banking/issued-cheques-bounce.vue') },
  { path: '/banking/received-cheques-bounce', meta: { title: 'Banking', requiresAuth: true }, name: 'Banking', component: () => import('../views/accounts/finance/banking/received-cheques-bounce.vue') },

  // Party Adjustment
  { path: '/party-adjustments/adjust-party-bills', meta: { title: 'Party Adjustments', requiresAuth: true }, name: 'Party Adjustments', component: () => import('../views/accounts/finance/party-adjustments/adjust-party-bills.vue') },
  { path: '/party-adjustments/credit-notes', meta: { title: 'Party Adjustments', requiresAuth: true }, name: 'Party Adjustments', component: () => import('../views/accounts/finance/party-adjustments/credit-notes.vue') },
  { path: '/party-adjustments/debit-notes', meta: { title: 'Party Adjustments', requiresAuth: true }, name: 'Party Adjustments', component: () => import('../views/accounts/finance/party-adjustments/debit-notes.vue') },
  // Opening Balance
  { path: '/opening-balances/opening-balances', name: 'Opening Balances', component: () => import('../views/accounts/finance/opening-balances/opening-balances.vue') },
  { path: '/opening-balances/party-opening-balances', name: 'Party Opening Balances', component: () => import('../views/accounts/finance/opening-balances/party-opening-balances.vue') },
  // vatcst
  { path: '/vatcst/vat-openings', meta: { title: 'VATCST', requiresAuth: true }, name: 'VATCST', component: () => import('../views/accounts/finance/vatcst/vat-openings.vue') },
  { path: '/vatcst/vat-payments', meta: { title: 'VATCST', requiresAuth: true }, name: 'VATCST', component: () => import('../views/accounts/finance/vatcst/vat-payments.vue') },
  { path: '/vatcst/vat-month-end', meta: { title: 'VATCST', requiresAuth: true }, name: 'VATCST', component: () => import('../views/accounts/finance/vatcst/vat-month-end.vue') },

  // Purchase

  { path: '/purchase', meta: { title: 'Finance', requiresAuth: true }, name: 'Purchase', component: () => import('../views/accounts/purchase/purchase.vue') },

  // Enquires
  { path: '/enquires/purchase-enquiries', meta: { title: 'Finance', requiresAuth: true }, name: 'Enquiries', component: () => import('../views/accounts/purchase/enquires/purchase-enquiries.vue') },
  { path: '/enquires/purchase-enquiries-cancellations', meta: { title: 'Finance', requiresAuth: true }, name: 'Enquiries', component: () => import('../views/accounts/purchase/enquires/purchase-enquiries-cancellations.vue') },
  { path: '/enquires/purchase-enquiries-againts-requisitions', meta: { title: 'Finance', requiresAuth: true }, name: 'Enquiries', component: () => import('../views/accounts/purchase/enquires/purchase-enquiries-againts-requisitions.vue') },


  // Requisitions
  { path: '/requisitions/purchase-requisitions', name: 'Requisitions', component: () => import('../views/accounts/purchase/requisitions/purchase-requisitions.vue') },
  { path: '/requisitions/purchase-requisitions-cancellations', name: 'Requisitions', component: () => import('../views/accounts/purchase/requisitions/purchase-requisitions-cancellations.vue') },

  // Quotations
  { path: '/quotations/purchase-quotations', name: 'Quotations', component: () => import('../views/accounts/purchase/quotations/purchase-quotations.vue') },
  { path: '/quotations/purchase-quotations-againts-enquires', name: 'Quotations', component: () => import('../views/accounts/purchase/quotations/purchase-quotations-againts-enquires.vue') },

  // Orders
  { path: '/orders/purchase-order', name: 'Orders', component: () => import('../views/accounts/purchase/orders/purchase-order.vue') },
  { path: '/orders/purchase-order-againts-quotations', name: 'Orders', component: () => import('../views/accounts/purchase/orders/purchase-order-againts-quotations.vue') },
  { path: '/orders/purchase-order-againts-requisitions', name: 'Orders', component: () => import('../views/accounts/purchase/orders/purchase-order-againts-requisitions.vue') },
  { path: '/orders/purchase-order-cancellation', name: 'Orders', component: () => import('../views/accounts/purchase/orders/purchase-order-cancellation.vue') },

  // Recipts
  { path: '/receipts/material-receipts', name: 'Receipts', component: () => import('../views/accounts/purchase/receipts/material-receipts.vue') },
  { path: '/receipts/material-receipts-againts-orders', name: 'Receipts', component: () => import('../views/accounts/purchase/receipts/material-receipts-againts-orders.vue') },

  // Invoices
  { path: '/invoices/purchase-voucher', name: 'Invoices', component: () => import('../views/accounts/purchase/invoices/purchase-voucher.vue') },
  { path: '/invoices/cash-purchase-voucher', name: 'Invoices', component: () => import('../views/accounts/purchase/invoices/cash-purchase-voucher.vue') },
  { path: '/invoices/purchase-voucher-againts-orders', name: 'Invoices', component: () => import('../views/accounts/purchase/invoices/purchase-voucher-againts-orders.vue') },
  { path: '/invoices/purchase-voucher-againts-receipts', name: 'Invoices', component: () => import('../views/accounts/purchase/invoices/purchase-voucher-againts-receipts.vue') },


  // Return
  { path: '/return/material-return', name: 'Return', component: () => import('../views/accounts/purchase/return/material-return.vue') },
  { path: '/return/purchase-return', name: 'Return', component: () => import('../views/accounts/purchase/return/purchase-return.vue') },
  { path: '/return/purchase-return-with-invoice-reference', name: 'Return', component: () => import('../views/accounts/purchase/return/purchase-return-with-invoice-reference.vue') },

  //  Login
  { path: '/login', name: 'Login', component: () => import('../views/auth/login.vue') },
  { path: '/accounts', name: 'Account', component: () => import('../views/auth/accounts.vue') },
  { path: '/404', name: 'Error', component: () => import('../views/error/404.vue') },


  
  // Reports 
  
  { path: '/reports/hr', name: 'Reports', component: () => import('../views/reports/hr/summary-reports.vue') },
  // { path: '/reports/finance', name: 'Reports', component: () => import('../views/reports/reports.vue') },
  { path: '/reports/finance', name: 'Reports', component: () => import('../views/reports/finance/finance-reports.vue') },
  { path: '/reports/sales', name: 'Reports', meta: { title: 'Reports', requiresAuth: true }, component: () => import('../views/reports/sales/sale-reports.vue') },
  { path: '/reports/sales/sales', name: 'Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/reports/sales/sales.vue') },
  { path: '/reports/sales/sales-credit-note', name: 'Invoices', meta: { title: 'Invoices', requiresAuth: true }, component: () => import('../views/reports/sales/sales-credit-note.vue') },  

  // Inventory
  { path: '/reports/inventory/in', name: 'Inventory', meta: { title: 'Inventory', requiresAuth: true }, component: () => import('../views/reports/inventory/inventory-in.vue') },
  { path: '/reports/inventory/out', name: 'Inventory', meta: { title: 'Inventory', requiresAuth: true }, component: () => import('../views/reports/inventory/inventory-out.vue') },
 
 
  // TRIAL-BALANCE
  { path: '/trialbalance', name: 'TrialBalance', component: () => import('../views/reports/finance/trialbalance.vue') },
  { path: '/trialbalance-segment', name: 'TrialBalanceSegment', component: () => import('../views/reports/finance/trialbalance-segment.vue') },
  // BALANCE-SHEET
  { path: '/balancesheet', name: 'BalanceSheet', component: () => import('../views/reports/finance/balance-sheet.vue') },
  { path: '/balancesheet-segment', name: 'BalanceSheetSegment', component: () => import('../views/reports/finance/balancesheet-segment.vue') },
  { path: '/balancesheet-division', name: 'BalanceSheetDivision', component: () => import('../views/reports/finance/balancesheet-division.vue') },
  // PROFIT & LOSS
  { path: '/profitloss', name: 'ProfitLoss', component: () => import('../views/reports/finance/profit-loss.vue') },
  { path: '/profitloss-segment', name: 'ProfitLossSegment', component: () => import('../views/reports/finance/profitloss-segment.vue') },
  { path: '/profitloss-previousyear', name: 'ProfitLossPreviousYear', component: () => import('../views/reports/finance/profitloss-previousyear.vue') },
  { path: '/profitloss-division', name: 'ProfitLossDivision', component: () => import('../views/reports/finance/profitloss-division.vue') },
  // OTHERS
  { path: '/cashflow', name: 'CashFlow', component: () => import('../views/reports/finance/cashflow.vue') },
  { path: '/fundsflow', name: 'FundsFlow', component: () => import('../views/reports/finance/fundsflow.vue') },

  // LEDGER
  { path: '/ledger', name: 'Ledger', component: () => import('../views/reports/ledger/standardreport.vue') },
  { path: '/ledger/statements-of-accounts', name: 'StatementsOfAccounts', component: () => import('../views/reports/ledger/statementsofaccounts.vue') },
  { path: '/ledger/statements-of-account-print', name: 'StatementsOfAccounts', component: () => import('../views/reports/ledger/statementsofaccounts-print.vue') },
  { path: '/ledger/data-vat', name: 'DataVat', component: () => import('../views/reports/ledger/datavat.vue') },
  // /ledger/statements-of-accounts
  { path: '/ledger-by-account-group', name: 'LedgerByAccountGroup', component: () => import('../views/reports/ledger-by-account-group.vue') },
  { path: '/ledger-with-bill-details', name: 'LedgerWithBillDetails', component: () => import('../views/reports/ledger-with-bill-details.vue') },
  { path: '/ledger-singlecurrency', name: 'LedgerSingleCurrency', component: () => import('../views/reports/ledger-singlecurrency.vue') },
  { path: '/ledger-multicurrency', name: 'LedgerMultiCurrency', component: () => import('../views/reports/ledger-multicurrency.vue') },
  { path: '/ledger-detailed2', name: 'LedgerDetailed2', component: () => import('../views/reports/ledger-detailed2.vue') },

  // DAY-BOOKS
  { path: '/reports/books/daybook', name: 'DayBook', component: () => import('../views/reports/books/daybook.vue') },
  { path: '/reports/books/brs', name: 'BRS', component: () => import('../views/reports/books/brs.vue') },
  { path: '/reports/books/bank-reconciliation', name: 'BankReconciliation', component: () => import('../views/reports/books/bank-reconciliation.vue') },
  { path: '/reports/books/bank-reconciliation-multicurrency', name: 'BankReconciliationMultiCurrency', component: () => import('../views/reports/books/bank-reconciliation-multicurrency.vue') },


  // RECEIVABLES AND PAYABLES
  { path: '/reports/receivables&payables/receivables', name: 'Receivables', component: () => import('../views/reports/receivables_and_payables/receivables.vue') },
  { path: '/reports/receivables&payables/accountreceivables', name: 'AccountReceivables', component: () => import('../views/reports/receivables_and_payables/accountreceivables.vue') },
  { path: '/reports/receivables&payables/payables', name: 'Payables', component: () => import('../views/reports/receivables_and_payables/payables.vue') },
  { path: '/reports/receivables&payables/accountpayables', name: 'AccountPayables', component: () => import('../views/reports/receivables_and_payables/accountpayables.vue') },
  { path: '/reports/receivables&payables/overduereceivables', name: 'OverdueReceivables', component: () => import('../views/reports/receivables_and_payables/overduereceivables.vue') },
  { path: '/reports/receivables&payables/overduepayables', name: 'OverduePayables', component: () => import('../views/reports/receivables_and_payables/overduepayables.vue') },
  { path: '/reports/receivables&payables/pendingbills-customers', name: 'PendingBillsForCustomers', component: () => import('../views/reports/receivables_and_payables/pendingbillsforcustomers.vue') },
  { path: '/reports/receivables&payables/pendingbills-customer', name: 'PendingBillsForCustomer', component: () => import('../views/reports/receivables_and_payables/pendingbillsforcustomer.vue') },
  { path: '/reports/receivables&payables/pendingbills-supplier', name: 'PendingBillsForSupplier', component: () => import('../views/reports/receivables_and_payables/pendingbillsforsupplier.vue') },
  { path: '/reports/receivables&payables/pendingbills-suppliers', name: 'PendingBillsForSuppliers', component: () => import('../views/reports/receivables_and_payables/pendingbillsforsuppliers.vue') },


  // BALANCES
  { path: '/reports/balances/customerbalances', name: 'CustomerBalances', component: () => import('../views/reports/balances/customerbalances.vue') },
  { path: '/reports/balances/supplierbalances', name: 'SupplierBalances', component: () => import('../views/reports/balances/supplierbalances.vue') },
  { path: '/reports/balances/trialbalance-treeview', name: 'TrialBalanceTreeView', component: () => import('../views/reports/balances/trialbalance-treeview.vue') },
  { path: '/reports/balances/accountbalances-treeview', name: 'AccountBalancesTreeView', component: () => import('../views/reports/balances/accountbalances-treeview.vue') },

  { path: '/reports/balances/accountbalances/standardreport', name: 'StandardReport', component: () => import('../views/reports/balances/accountbalances/standardreport.vue') },
  { path: '/reports/balances/accountbalances/subaccountsummary', name: 'SubAccountSummary', component: () => import('../views/reports/balances/accountbalances/subaccountsummary.vue') },


  // OPEN-TRANSACTIONS
  { path: '/reports/opentransactions/pendingbookingofothercost', name: 'PendingBookingOfOtherCost', component: () => import('../views/reports/opentransactions/pending-booking-of-other-cost.vue') },
  { path: '/reports/opentransactions/pendingbookingofothercost-import', name: 'PendingBookingOfOtherCostImport', component: () => import('../views/reports/opentransactions/pending-booking-of-other-cost-import.vue') },

  // TRANSACTION-LISTING
  { path: '/reports/transactionslisting/complexjv-report', name: 'ComplexJvReport', component: () => import('../views/reports/transaction-listing/journal-entries-complex/complex-jv-report.vue') },
  { path: '/reports/transactionslisting/cashreceipts', name: 'Cash Receipts', component: () => import('../views/reports/transaction-listing/cashreceipts.vue') },
  { path: '/reports/transactionslisting/bankreceipts', name: 'Bank Receipts', component: () => import('../views/reports/transaction-listing/bankreceipts.vue') },
  { path: '/reports/transactionslisting/cashpayments', name: 'Cash Payments', component: () => import('../views/reports/transaction-listing/cashpayments.vue') },
  { path: '/reports/transactionslisting/bankpayments', name: 'Bank Payments', component: () => import('../views/reports/transaction-listing/bankpayments.vue') },
  // { path: '/reports/transactionslisting/cashpayments', name: 'Cash Payments', component: () => import('../views/reports/transaction-listing/cashpayments.vue') },
  // { path: '/reports/transactionslisting/cashpayments', name: 'Cash Payments', component: () => import('../views/reports/transaction-listing/cashpayments.vue') },

  // TAXES

  // vat-cs-tax-details
  { path: '/reports/taxes/vat-cst-tax-details/vat-cst-output', name: 'VatCstOutput', component: () => import('../views/reports/taxes/vat-cs-tax-details/vat-cst-output.vue') },
  { path: '/reports/taxes/vat-cst-tax-details/vat-cst-input', name: 'VatCstInput', component: () => import('../views/reports/taxes/vat-cs-tax-details/vat-cst-input.vue') },
  { path: '/reports/taxes/vat-cst-tax-details/tax-summary', name: 'TaxSummary', component: () => import('../views/reports/taxes/vat-cs-tax-details/tax-summary.vue') },

  // gcc
  { path: '/reports/taxes/gcc/quarterlysales', name: 'QuarterlySales', component: () => import('../views/reports/taxes/gcc/quarterly-sales.vue') },
  { path: '/reports/taxes/gcc/quarterlypurchase', name: 'QuarterlyPurchase', component: () => import('../views/reports/taxes/gcc/quarterly-purchase.vue') },
  { path: '/reports/taxes/gcc/vatreturn', name: 'VatReturn', component: () => import('../views/reports/taxes/gcc/vat-return.vue') },
  { path: '/reports/taxes/gcc/bahrainvat', name: 'BahrainVat', component: () => import('../views/reports/taxes/gcc/bahrain-vat.vue') },


  {
    path: '*',
    component: () => import('../views/error/404.vue')
  },

]


const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (localStorage.getItem('jwt') === null) {
    if (to.path !== '/login') { next('/login') } else { next() }
  }
  else {
    if (to.path == '/login') {
      localStorage.removeItem("jwt");
    }
    next();
  }

});

export default router