




























import { Component, Vue } from "vue-property-decorator";

@Component
export default class SidebarSaleReports extends Vue {
   public data = [
    {
      item: "Invoices",
      visibility: false,
      collapse: "collapse-1",
      child: [
        { item: "Sale", route: "/reports/sales/sales" },
        { item: "Sale (Credit Note)", route: "/reports/sales/sales-credit-note" },
        // { item: "Sale (Credit Note General)", route: "/reports/sales/general-credit-note" },
        // { item: "Journal Entries [Complex]", route: "/journal/complex-entry" },
        // { item: "Book Incomes or Receivable", route: "/journal/book-income" },
        // { item: "Book Expense or Payable", route: "/journal/book-expense" },
      ],
    },
  ];

  mounted() {
    let index = this.data.findIndex((x) => {
      return x.item === this.$route.name;
    });
    if (index >= 0) {
      this.data[index]["visibility"] = true;
    }
  }
}
